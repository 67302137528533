<template>
    <div class="showcases-slider showcase pb-3" v-if="products.length">
        <div class="showcase-products mb-4 pt-4 pl-1 pr-1">
            <div class="title text-center"> {{ name }}</div>
        </div>
        <b-container>
            <div class="mx-n2 d-flex">
                <swiper class="swiper showcases-swiper"
                        ref="swiper"
                        :options="swiperOption"
                        :auto-update="true"
                        :auto-destroy="true"
                        :delete-instance-on-destroy="true"
                        :cleanup-styles-on-destroy="true"
                        :data-n-cols="$wdconfig.modules.product.size_in_listing === 'large' ? 3 : 4"
                >
                    <swiper-slide v-for="product in products" :key="product.code">
                        <Product :product="product" :hover_effect="false" class="mx-2"/>
                    </swiper-slide>
                    <div class="swiper-button-prev mx-0" slot="button-prev">
                        <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l-7 7 7 7"/>
                        </svg>
                    </div>
                    <div class="swiper-button-next mx-0" slot="button-next">
                        <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 5l7 7-7 7"/>
                        </svg>
                    </div>
                </swiper>
            </div>
            <div class="swiper-pagination pt-3 pb-4" :class="uid" slot="pagination" ref="pagination"></div>
        </b-container>
    </div>
</template>

<script>
    import Product from '~/components/Product'

    export default {
        components: {
            Product
        },
        props: {
            id: {
                default: 0
            },
            name: {
                default: ''
            },
            products: {
                default: () => []
            }
        },
        computed: {
            uid() {
                return `component-${this._uid}`
            },
            swiperOption() {
                return {
                    infinite: true,
                    loop: true,
                    lazy: true,
                    centerInsufficientSlides: true,
                    direction: 'horizontal',
                    pagination: {
                        el: `.${this.uid}.swiper-pagination`,
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    slidesPerView: 'auto'
                }
            }
        }
    }
</script>
