<template>
    <div class="h-100 product-card" :class="[{'product-on-hover': onhover}, displayAsList ? 'alternative w-100' : '']" @mouseover="hover = true" @mouseleave="hover = false">
        <component :is="no_link? 'div' : 'nuxt-link'" v-bind="no_link ? '' : {'to': product.route}" class="component d-flex" :class="displayAsList? 'justify-content-around' : 'flex-column h-100'">
            <div :class="displayAsList? 'col-md-4 d-flex align-items-center' : ''">
                <div class=" aspect-ratio mb-2 w-100" :class="[getClassAspectRatio, {'mt-2': displayAsList}]">
                    <div class="aspect-content" v-if="product.gallery.length">
                        <img :class="$wdconfig.modules.product.image_object_fit" :src="image_cache(product.gallery[0].image, product.gallery[0].image_cache)" v-show="multiple_images === false || !onhover" :alt="product.name" :title="product.name" loading="lazy" />
                        <img :class="$wdconfig.modules.product.image_object_fit" :src="image_cache(product.gallery[1].image, product.gallery[1].image_cache)" v-show="onhover" v-if="multiple_images" :alt="product.name" :title="product.name" loading="lazy" />
                    </div>
                    <div class="aspect-content" v-else>
                        <img :class="$wdconfig.modules.product.image_object_fit" :src="imageUrl('')" :alt="product.name" :title="product.name" loading="lazy" />
                    </div>
                </div>
            </div>
            <div class="wrapper" :class="displayAsList? 'col-8 col-md-8 flex-column justify-content-center py-4' : 'align-items-baseline flex-grow-1'">
                <div :class="displayAsList? '':'flex-column'" class="tags d-flex px-2" v-if="product.tags && typeof product.tags === 'object' && typeof product.tags.list === 'object' || displayAsList && product.active === 'yes'">
                    <div class="discount-porcentage mr-2 mb-1" v-if="product.price_old > 0 && product.active === 'yes' && percentage > 0 && displayAsList"> -{{ percentage }}% </div>
                    <div :class="displayAsList? 'mr-2': ''" class="tag mb-1" v-for="tag in product.tags.list" :key="tag.id"> {{ tag.tag }}</div>
                </div>
                <div class="discount-porcentage" v-if="product.price_old > 0 && product.active === 'yes' && percentage > 0 && !displayAsList"> -{{ percentage }}% </div>
                <div class="name mb-2 px-2" :class="displayAsList && product.active === 'yes'? 'd-flex flex-column justify-content-end':''">
                    <div v-html="product.name"></div>
                    <div v-if="code">
                        <small>Cód.: {{ code }}</small>
                    </div>
                </div>
                <template v-if="product.view_prices && product.view_prices.length">
                    <HiddenPrice class="hidden-price p-2" :hidden_status="product.view_prices"/>
                </template>
                <template v-else>
                    <div class="per-quantity px-2" v-if="product.promotions.length">
                        <svg class="discount-svg" xmlns="http://www.w3.org/2000/svg" width="12.259" height="12.259" viewBox="0 0 12.259 12.259">
                            <g transform="translate(-0.002)">
                                <path class="a" d="M11.962,6.514,6.176.736A2.506,2.506,0,0,0,5.351.214,2.5,2.5,0,0,0,4.4,0H1.038A1,1,0,0,0,.309.307,1,1,0,0,0,0,1.036V4.4a2.5,2.5,0,0,0,.214.947,2.411,2.411,0,0,0,.522.817L6.524,11.96a.991.991,0,0,0,.728.3,1.036,1.036,0,0,0,.736-.3l3.973-3.981a.991.991,0,0,0,.3-.728A1.037,1.037,0,0,0,11.962,6.514ZM3.324,3.322a1,1,0,0,1-.732.3A1.036,1.036,0,0,1,1.556,2.589,1.036,1.036,0,0,1,2.591,1.554,1.036,1.036,0,0,1,3.627,2.589,1,1,0,0,1,3.324,3.322Z" transform="translate(0)" />
                            </g>
                        </svg>
                        <div class="mytooltip">
                            <div class="text-left">Economize</div>
                            <div v-for="promotion in product.promotions" :key="promotion.price">A partir de {{promotion.quantity_initial}} {{product.unit.name}}
                                <span class="price"> {{promotion.price | currency}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="product-prices px-2 pb-2 justify-self-end d-flex flex-column" :class="displayAsList? 'justify-content-start':'justify-content-end h-100'" v-if="product.active === 'yes'">
                        <div class="price_old" v-if="product.price_old !== '0.00'">
                            {{ product.price_old | currency }}
                        </div>
                        <template v-if="$wdconfig.modules.product.show_discount_price && product.payments && product.payments.length">
                            <div class="d-flex align-items-baseline">
                                <div class="price">
                                    {{ product.payments[0]['amount'] | currency }}
                                </div>
                            </div>
                            <div class="condition-payment mb-2"> &Agrave; vista no {{ product.payments[0]['name'] }}
                            </div>
                            <div class="d-flex align-items-center" v-if="product.payment_condition && product.payment_condition.installments > 1">
                                <div class="condition-payment"> ou {{ product.payment_condition.installments}} x {{(product.price/product.payment_condition.installments) | currency}}
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div v-if="shouldDisplayVariationPrice" class="price_old text-decoration-none"> &Agrave; partir de </div>
                            <div class="d-flex align-items-baseline">
                                <div class="price mb-1">
                                    {{ (shouldDisplayVariationPrice ? lowestVariationPrice : product.price) | currency }}
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-if="product.payment_condition && product.payment_condition.installments > 1">
                                <div class="condition-payment"> ou {{ product.payment_condition.installments}} x {{(product.price/product.payment_condition.installments) | currency}}
                                </div>
                            </div>
                            <div class="d-flex align-items-center" v-if="$wdconfig.modules.product.payment_discount && product.payments && product.payments.length">
                                <div class="condition-payment"> ou {{ parseFloat(product.payments[0]['discount']['value']) }}% de desconto no {{ product.payments[0]['name'] }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-if="product.active !== 'yes' && displayAsList" class="px-2 mb-2">
                        <div class="mb-2 unavailable">Indisponível</div>
                    </div>
                    <div v-if="product.active === 'yes' && displayAsList && product.variations.list.length">
                        <div class="product-options p-2 options-on-hover" v-if="product.active === 'yes'" :class="displayAsList? 'flex-column d-none d-sm-none d-md-flex d-lg-flex':'position-absolute'" @click="preventLinkClick($event)">
                            <div class="d-flex justify-content-start flex-wrap pb-1" v-if="product.variations.list.length">
                                <div class="variation-option mb-2 mr-2" v-for="variation in product.variations.list" :key="variation.id">
                                    <label class="name mb-0" :for="uid + '_variation_' + variation.id">{{ variation.name }}</label>
                                    <select :id="uid + '_variation_' + variation.id" v-model="variation.value" @focus="focus = true; clickChangeVariation(variation)" @blur="focus = false" @change="focus = true; changeVariation(true)" class="product-select-variation">
                                        <option :disabled="option.disabled" v-for="option in variation.options" :key="option.value" :value="option.value">
                                            {{option.text}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 buy d-sm-none d-md-block" v-if="product.active === 'yes' && displayAsList" @click="preventLinkClick($event)">
                        <button class="btn-add-cart btn-default" @click="$router.push(product.route)" ref="btn-add-cart" v-if="product.additional && product.additional.list.length > 0 && no_link !== true"> VISUALIZAR</button>
                        <button class="btn-add-cart btn-default" @click="addProduct(product, list_code)" ref="btn-add-cart" v-else> {{ $wdconfig.modules.product.add_to_cart_button_text }}</button>
                    </div>
                </template>
            </div>
            <div v-if="product.active !== 'yes' && !displayAsList && (!product.view_prices || !product.view_prices.length)" class="px-2 mb-2">
                <div class="mb-2 unavailable">Indisponível</div>
            </div>
        </component>
        <div class="position-relative" v-if="product.active === 'yes' && hover_effect && !displayAsList && (!product.view_prices || !product.view_prices.length)" :class="{'pb-4': !product.view_prices || !product.view_prices.length}">
            <div class="position-absolute product-options">
                <div class="p-2 options-on-hover" v-if="product.active === 'yes'">
                    <div class="d-flex justify-content-between flex-wrap pb-1" v-if="product.variations.list.length">
                        <div class="variation-option mb-2" v-for="variation in product.variations.list" :key="variation.id">
                            <label class="name mb-0" :for="uid + '_variation_' + variation.id">{{ variation.name }}</label>
                            <select :id="uid + '_variation_' + variation.id" v-model="variation.value" @focus="focus = true; clickChangeVariation(variation)" @blur="focus = false" @change="focus = true; changeVariation(true)" class="product-select-variation">
                                <option :disabled="option.disabled" v-for="option in variation.options" :key="option.value" :value="option.value">
                                    {{option.text}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="mt-1 mb-2 buy">
                        <button class="btn-add-cart btn-default" @click="$router.push(product.route)" ref="btn-add-cart" v-if="product.additional && product.additional.list.length > 0 && no_link !== true"> VISUALIZAR</button>
                        <button class="btn-add-cart btn-default" @click="addProduct(product, list_code)" ref="btn-add-cart" v-else> {{ $wdconfig.modules.product.add_to_cart_button_text }}</button>
                    </div>
                </div>
            </div>
        </div>
        <ModalProductVariation :product="product" @selected="(code) => { this.product_code = code }" ref="modal-variation" />
    </div>
</template>
<script>
    import utils from '~/mixins/utils';
    import order from '~/mixins/order';
    import product from '~/mixins/product';
    import ModalProductVariation from "~/components/ModalProductVariation";
    import HiddenPrice from '~/components/HiddenPrice.vue';

    export default {
        components: {ModalProductVariation,HiddenPrice},
        mixins: [
            utils,
            order,
            product
        ],
        props: {
            product: {
                type: Object,
                required: true
            },
            no_link: {
                type: Boolean,
                default: false
            },
            list_code: {
                default: 0
            },
            hover_effect: {
                type: Boolean,
                default: true
            },
            displayAsList:{
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                hover: false,
                focus: false,
                product_code: 0
            }
        },
        computed: {
            uid() {
                return this._uid
            },
            onhover() {
                return this.hover_effect && (this.hover || this.focus)
            },
            multiple_images() {
                return !!(typeof this.product.gallery === 'object' && this.product.gallery.length > 1 && this.$wdconfig.modules.product.change_image_hover_in_listing)
            },
            code() {
                try {
                    if (this.$wdconfig.modules.product.code_in_listing === 'codigo_integracao') {
                        if (this.product.integration_code !== '' && this.product.integration_code !== '0') {
                            return this.product.integration_code
                        }
                    }
                    if (this.$wdconfig.modules.product.code_in_listing === 'codigo_barras') {
                        if (this.product.barcode !== '' && this.product.barcode !== '0') {
                            return this.product.barcode
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
                return this.product.code
            },
            percentage() {
                let result = (((this.product.price * 100) / this.product.price_old) - 100) * -1
                return Math.round(result)
            },
        },
        methods:{
            preventLinkClick(event) {
                if(this.displayAsList){
                    event.preventDefault();
                }
            },
        }
    }
</script>
