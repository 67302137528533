import {find, findIndex} from 'lodash';

export default {
    data() {
        return {
            product_base: '',
            selecteds: {}
        }
    },
    mounted() {
        try {
            this.product.variations.list.forEach(el => {
                const options = this.filterValidVariation(el.options)
                if (options.length === 1) {
                    const option = options[0]
                    el.value = option['value']
                    this.changeVariation(false, option)
                }
            })
        } catch (e) {
            console.error(e)
        }
    },
    methods: {
        changeQuantity(product, op) {
            let precision = 0
            if ('unit' in product && 'precision' in product.unit) {
                precision = product.unit.precision
            }
            let multiplicador = 1;
            if (product.multiple.active) {
                multiplicador = parseFloat(product.multiple.quantity)
                if (multiplicador < 1 && precision < 1) {
                    precision = 3
                }
            }
            if (op === '-' && !isNaN(product.qty) && product.qty > multiplicador) {
                product.qty = (parseFloat(product.qty) - multiplicador).toFixed(precision)
            }
            if (op === '+' && !isNaN(product.qty)) {
                product.qty = (parseFloat(product.qty) + multiplicador).toFixed(precision)
            }
        },
        clickChangeVariation(obj) {
            obj.value = 0;
            this.changeVariation(false);
        },
        changeVariation(changeFocus, option = {}) {
            let filter = {};
            let itens = [];
            let enabledItens = [];
            this.selecteds = {}
            let vm = this;
            if (this.product_base === '') {
                this.product_base = Object.assign({}, this.product);
            }
            this.product.gallery = this.product_base.gallery;
            if (typeof option.gallery === 'object' && option.gallery.length) {
                this.product.gallery = option.gallery;
            }
            this.product.price = this.product_base.price
            this.product.payments = this.product_base.payments
            this.product.variations.list.forEach(function (element, index, array) {
                if (element.value > 0) {
                    filter['variation' + element.id] = element.value;
                    vm.selecteds[element.id] = element.value
                }
            })
            findIndex(this.product.variations.products_variations, function (o) {
                let index = [
                    o
                ];
                if (findIndex(index, filter) === 0) {
                    itens.push(o.code);
                }
            });
            find(this.product.variations.products, function (o) {
                if (itens.indexOf(o.code) !== -1) {
                    find(o.variations, function (u) {
                        enabledItens.push(u);
                    });
                }
            });
            find(vm.product.variations.list, function (v) {
                let ObjId = v.id;
                let filter = false;
                find(vm.product.variations.list, function (oO) {
                    if (oO.id !== ObjId) {
                        if (oO.value > 0) {
                            filter = true;
                            // vm.selecteds.push(oO.value)
                        }
                    }
                });
                if (filter) {
                    find(v.options, function (u) {
                        // u.text = u.text.replace(' - Não disponivel', '');
                        if (u.value > 0) {
                            u.disabled = false;
                            if (enabledItens.indexOf(u.value) === -1) {
                                u.disabled = true;
                                // u.text += ' - Não disponivel';
                            }
                        }
                    });
                } else {
                    find(v.options, function (u) {
                        // u.text = u.text.replace(' - Não disponivel', '');
                        u.disabled = false;
                    });
                }
            });
            find(vm.product.variations.list, function (v) {
                v.options.forEach((o) => {
                    if (o.value > 0) {
                        let product = find(vm.product.variations.products_variations, (p) => {
                            if (o.value === p['variation' + v.id]) {
                                for (const id in vm.selecteds) {
                                    if (!('variation' + id in p)) {
                                        return false
                                    }
                                    if (v.id === id) {
                                        continue
                                    }
                                    if (vm.selecteds[id] !== p['variation' + id]) {
                                        return false
                                    }
                                    return true
                                }
                            }
                        })
                        // o.disabled = true
                        if (product) {
                            o.disabled = false
                        }
                    }
                })
            })
            if (changeFocus) {
                if (window.innerWidth <= 991 && vm.$refs['btn-mobile-add-cart']) {
                    vm.$refs['btn-mobile-add-cart'].focus()
                } else {
                    if (vm.$refs['btn-add-cart']) {
                        vm.$refs['btn-add-cart'].focus()
                    }
                }
            }
            let variation_selecteds = true;
            find(vm.product.variations.list, function (o) {
                if (o.value <= 0) {
                    variation_selecteds = false;
                }
            });
            if (variation_selecteds === false) {
                this.product_code = 0;
                return;
            }
            let index = findIndex(this.product.variations.products_variations, filter);
            if (index < 0) {
                this.product_code = 0;
                this.product.active = 'no';
                return;
            }
            this.product_code = this.product.variations.products_variations[index]['code'];
            this.product.active = this.product.variations.products[this.product_code]['active'];
            this.product.price = this.product.variations.products[this.product_code]['price'];
            this.product.price_old = this.product.variations.products[this.product_code]['price_old'];
            this.product.gallery = this.product.variations.products[this.product_code]['gallery'];
            this.product.payments = this.product.variations.products[this.product_code]['payments'];
        },
        filterValidVariation(variations = []) {
            try {
                return variations.filter(el => {
                    return el.value
                })
            } catch (e) {
                console.error(e)
            }
            return []
        },
        selectedOptionVariation(variation) {
            try {
                return variation.options.find(el => {
                    return variation.value === el.value
                })
            } catch (e) {
                console.error(e)
            }
            return {}
        }
    },
    computed: {
        lowestVariationPrice() {
            let productVariations = Object.values(this.product.variations.products);
            let pricesArray = [];
            if (productVariations.length > 0) {
                for (let product in this.product.variations.products) {
                    const variation = this.product.variations.products[product];
                    pricesArray.push(variation.price);
                }
                const hasVariationInPrices = !pricesArray.every((price) => price === pricesArray[0]);
                if (hasVariationInPrices) {
                    const smallestPrice = pricesArray.reduce((smallest, current) => (parseFloat(current) < parseFloat(smallest) ? current : smallest), pricesArray[0]);
                    return smallestPrice;
                }
                return false;
            }
            return false;
        },
        shouldDisplayVariationPrice() {
            return this.lowestVariationPrice && Object.keys(this.selecteds).length === 0;
        },
    },
}
