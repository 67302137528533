<template>
    <div v-if="activeBlock">
        <div class="pre-footer-info pt-4 pb-5 pb-lg-4">
            <b-container>
                <b-row class="justify-content-sm-center">
                    <b-col cols="12" md="4" class="text-center pre-footer-info-item" v-if="info.name1 || info.text1">
                        <div class="mt-4 mb-2 title-info" v-html="info.name1"></div>
                        <div class="text mb-4" v-html="info.text1"></div>
                    </b-col>
                    <b-col cols="12" md="4" class="text-center pre-footer-info-item" v-if="info.name2 || info.text2">
                        <div class="mt-4 mb-2 title-info" v-html="info.name2"></div>
                        <div class="text mb-4" v-html="info.text2"></div>
                    </b-col>
                    <b-col cols="12" md="4" class="text-center pre-footer-info-item" v-if="isSocialActive.length">
                        <div class="mt-4 mb-2 title-info" v-html="info.name3 || 'Nos acompanhe'"></div>
                        <div class="text mb-4 d-flex social-network justify-content-center">
                            <div v-for="social in isSocialActive" :key="social.id" class="pr-3">
                                <a :href="social.link" :title="social.title" rel="nofollow" target="_blank">
                                    <img :src="social.image" :alt="social.name" :title="social.title" width="25" height="25">
                                </a>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                info: {}
            }
        },
        computed: {
            activeBlock() {
                try {
                    return this.isSocialActive.length || (this.info.name1 || info.text1 || this.info.name2 || this.info.text2)
                } catch (e) {
                    console.log(e)
                    return false
                }
            },
            isSocialActive() {
                let isActive = []
                let whatsapp_pos = Object.keys(this.$wdconfig.default.social).indexOf('whatsapp')
                let social_list = Object.values(this.$wdconfig.default.social)
                social_list.splice(whatsapp_pos, 1)
                social_list.map(elem => {
                    if (elem.active === "true") {
                        isActive.push(elem)
                    }
                })
                return isActive
            }
        },
        async fetch() {
            await this.$axios.$get(`api/v1/cms/?page_alias=informacoes-pre-rodape`).then((res) => {
                if (res.status === 'success') {
                    this.info = res.data
                }
            }).catch(e => {
                console.error(e)
            })
        }
    }
</script>
