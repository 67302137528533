<template>
    <div class="showcase pb-3" v-if="products.length">
        <div class="showcase-products mb-4 pt-4 pl-1 pr-1">
            <div class="title text-center"> {{ name }}</div>
        </div>
        <b-container>
            <component  v-if="products.length" :is="$wdconfig.modules.product.card_type === 'row'? 'b-col' : 'b-row'" :class="$wdconfig.modules.product.card_type === 'row'? 'mb-3' : 'mx-n2'">
                <component :is="$wdconfig.modules.product.card_type === 'row'? 'b-row' : 'b-col'" class="mb-3 px-2" v-for="product in products" :key="product.code" v-bind="$wdconfig.modules.product.card_type !== 'row' ? { md: $wdconfig.modules.product.size_in_listing === 'large' ? 4 : 3, cols: 6 } : {}">
                    <Product :product="product" :displayAsList="$wdconfig.modules.product.card_type === 'row'"/>
                </component>
            </component>
        </b-container>
    </div>
</template>

<script>
    import Product from '~/components/Product'

    export default {
        components: {
            Product
        },
        props: {
            id: {
                default: 0
            },
            name: {
                default: ''
            },
            products: {
                default: () => []
            }
        }
    }
</script>
