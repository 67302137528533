<template>
    <div>
        <component class="py-2" :is="this.$store.getters.isAuth? 'span' : 'nuxt-link'" v-bind="this.$store.getters.isAuth ? {} : { to: `/login?next=${this.$route.path}` }">
            {{ message }}
        </component>
    </div>
</template>
<script>
    import utils from "~/mixins/utils";

    export default {
        mixins: [utils],
        props: {
            hidden_status: {
                type: String,
                default: ''
            },
            message_not_authenticated: {
                type: String,
                default: ''
            },
            message_not_permission: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                messages: {
                    not_authenticated: 'Faça login para consultar o preço e adquirir o produto.',
                    not_permission: 'Você ainda não tem permissão para visualizar o preço.'
                }
            };
        },
        computed: {
            message() {
                if (this.hidden_status === 'not_permission') {
                    return !this.$store.getters.isAuth ? this.messages.not_authenticated : this.messages.not_permission;
                }
                return this.messages.not_authenticated;
            }
        },
        created() {
            if ('b2b' in this.$wdconfig.modules) {
                if (this.$wdconfig.modules.b2b.not_authenticated_price_message) {
                    this.messages.not_authenticated = this.$wdconfig.modules.b2b.not_authenticated_price_message;
                }
                if (this.$wdconfig.modules.b2b.not_permission_price_message) {
                    this.messages.not_permission = this.$wdconfig.modules.b2b.not_permission_price_message;
                }
            }
            if (this.message_not_authenticated) {
                this.messages.not_authenticated = this.message_not_authenticated;
            }
            if (this.message_not_permission) {
                this.messages.not_permission = this.message_not_permission;
            }
        },
    };
</script>
