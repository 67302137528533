<template>
    <div class="showcases-content-slider showcase pb-3" v-if="total">
        <div class="showcase-products mb-4 pt-4 pl-1 pr-1" v-if="content.name1">
            <div class="title text-center"> {{ content.name1 }}</div>
        </div>
        <b-container>
            <div class="mx-n2">
                <swiper class="swiper showcases-swiper"
                        ref="swiper"
                        :options="swiperOption"
                        :auto-update="true"
                        :auto-destroy="true"
                        :delete-instance-on-destroy="true"
                        :cleanup-styles-on-destroy="true"
                >
                    <swiper-slide v-for="item in content.category1.list" :key="item.id" class="px-2 text-center">
                        <component :is="item.name1 !== '' ? 'a' : 'div'" v-bind="item.name1 !== '' ? {href: item.name1} : ''" @click.prevent="url_redirect(item.name1)" class="d-block w-100">
                            <div class="aspect-ratio ratio-1-1">
                                <div class="aspect-content">
                                    <img :src="imageUrl(item.image1, 172)" :alt="item.name" :title="item.name" class="rounded-circle img-fluid object-fit-cover" width="172" height="172" loading="lazy">
                                </div>
                            </div>
                            <span class="d-block mt-2">{{ item.name }}</span>
                        </component>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="swiper-pagination pt-3 pb-4" :class="uid" slot="pagination" ref="pagination"></div>
        </b-container>
    </div>
</template>

<script>
    import utils from "~/mixins/utils";

    export default {
        mixins: [
            utils
        ],
        data() {
            return {
                content: []
            }
        },
        computed: {
            uid() {
                return `component-${this._uid}`
            },
            total() {
                try {
                    return this.content.category1.list.length
                } catch (e) {
                    return 0
                }
            },
            swiperOption() {
                return {
                    centerInsufficientSlides: true,
                    pagination: {
                        el: `.${this.uid}.swiper-pagination`,
                        clickable: true
                    },
                    slidesPerView: 'auto'
                }
            }
        },
        async fetch() {
            if (!!this.$wdconfig.modules.cms.page_id_showcases_content_slider) {
                await this.$axios.$get(`api/v1/cms/?page_id=${this.$wdconfig.modules.cms.page_id_showcases_content_slider}&categories_content=true`).then((res) => {
                    if (res.status === 'success') {
                        this.content = res.data
                    }
                }).catch(e => {
                    console.error(e)
                })
            }
        }
    }
</script>
