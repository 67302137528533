<template>
    <div class="multiple-banner">
        <div v-if="primaryBanner.length || secondaryBanner.length" class="mt-lg-4">
            <div class="container-lg">
                <div class="row">
                    <b-col :lg="secondaryBanner.length ? '8' : '12'" v-if="banners.length" class="mb-2 mb-lg-4">
                        <swiper class="swiper" :options="swiperOption" :key="swiperKey">
                            <swiper-slide v-for="image in banners" :key="image.id" class="px-1 px-lg-0">
                                <component :is="image.link ? 'a' : 'div'" v-bind="image.link ? {href: image.link, target: image.target} : ''" @click.prevent="image.link ? url_redirect(image.link, image.target) : ''">
                                    <div class="aspect-ratio ratio-16-9">
                                        <div class="aspect-content">
                                            <img class="object-fit-cover" :src="image_cache(image.file, image.file_cache1140)" width="1140" height="641" :alt="image.name" :title="image.name">
                                        </div>
                                    </div>
                                </component>
                            </swiper-slide>
                            <div class="swiper-pagination d-none d-lg-block" slot="pagination"></div>
                        </swiper>
                    </b-col>
                    <b-col v-if="secondaryBanner.length" class="d-none d-lg-block">
                        <b-row>
                            <b-col v-for="image in secondaryBanner" :key="image.id" :lg="primaryBanner.length ? 12 : 6">
                                <component :is="image.link ? 'a' : 'div'" v-bind="image.link ? {href: image.link, target: image.target} : ''" @click.prevent="image.link ? url_redirect(image.link, image.target) : ''">
                                    <div class="aspect-ratio ratio-16-9 mb-3">
                                        <div class="aspect-content">
                                            <img class="object-fit-cover" :src="image_cache(image.file, image.file_cache380)" width="380" height="214" :alt="image.name" :title="image.name">
                                        </div>
                                    </div>
                                </component>
                            </b-col>
                        </b-row>
                    </b-col>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '~/mixins/utils';
import {Swiper, SwiperSlide} from "vue-awesome-swiper";

export default {
    mixins: [utils],
    props: {
        primaryBanner: {
            default: () => [],
            type: Array
        },
        secondaryBanner: {
            default: () => [],
            type: Array
        },
        size: {
            type: String, Number,
            default: ''
        },
        loading: {
            type: String,
            default: ''
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            swiperKey: 0,
            banners: [],
            swiperOption: {
                loop: true,
                centeredSlides: true,
                slidesPerView: 'auto',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            }
        }
    },
    created() {
        this.banners = this.primaryBanner
    },
    mounted() {
        this.updateBanners()
        window.addEventListener('resize', () => {
            this.updateBanners()
        })
        window.removeEventListener('resize', () => {
            this.updateBanners()
        });
    },
    methods: {
        updateBanners() {
            if (process.client) {
                if (window.innerWidth < 992) {
                    this.banners = this.primaryBanner.concat(this.secondaryBanner)
                } else {
                    this.banners = this.primaryBanner
                }
                this.swiperOption.autoplay = this.banners.length > 1 ? { disableOnInteraction: false, pauseOnMouseEnter: true } : false
                this.swiperKey++
            }
        }
    }
}
</script>
