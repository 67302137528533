<template>
    <div v-if="banners.length">
        <b-container>
            <b-row class="justify-content-center">
                <b-col cols="12" md="3" v-for="image in banners" :key="image.id" class="my-2 text-center">
                    <component :is="image.link ? 'a' : 'div'" v-bind="image.link ? {href: image.link, target: image.target} : ''" @click.prevent="image.link ? url_redirect(image.link, image.target) : ''">
                        <img :src="image_cache(image.file, image.file_cache255)" :alt="image.name" :title="image.name" class="img-fluid" :loading="loading"/>
                    </component>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import utils from "~/mixins/utils";

    export default {
        mixins: [
            utils
        ],
        props: {
            banners: {
                default: () => [],
                type: Array
            },
            loading: {
                type: String,
                default: ''
            }
        }
    }
</script>
